import controller_0 from '@symfony/ux-autocomplete/dist/controller.js';
import controller_1 from '@symfony/ux-chartjs/dist/controller.js';
import controller_2 from '@symfony/ux-dropzone/dist/controller.js';
import '@symfony/ux-dropzone/dist/style.min.css';
import controller_3 from '@symfony/ux-live-component/dist/live_controller.js';
import '@symfony/ux-live-component/dist/live.min.css';
import controller_4 from '@symfony/ux-turbo/dist/turbo_controller.js';
import controller_5 from '@symfony/ux-turbo/dist/turbo_stream_controller.js';
export default {
  'symfony--ux-autocomplete--autocomplete': controller_0,
  'symfony--ux-chartjs--chart': controller_1,
  'symfony--ux-dropzone--dropzone': controller_2,
  'live': controller_3,
  'symfony--ux-turbo--turbo-core': controller_4,
  'symfony--ux-turbo--mercure-turbo-stream': controller_5,
};